import styles from "./Footer.module.css";
import logo from "../../assets/FreshStartCleanersLogoSVG.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className={styles.flexCenter}>
        <img src={logo} alt="Logo" width="150px" />
        <p>Transforming Spaces. One clean at a time.</p>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/account">My Account</Link>
          </li>
          <li>
            <Link to="/corporate">Corporate Cleanings</Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
