export const validateForm = (
  formData,
  requiredKeys = "*",
  additionalChecks
) => {
  for (let [field, response] of Object.entries(formData)) {
    if (requiredKeys === "*" || requiredKeys[field]) {
      if (response === undefined) return false;
      if (typeof response === "string" && response.trim() === "") return false;
    }
  }

  for (let { fieldName, validationCase } of additionalChecks) {
    if (!validationCase(formData[fieldName])) {
      return false;
    }
  }

  return true;
};

export const validateZipCode = (zipCode) => {
  // Checks for a 5 digit number
  const re = new RegExp("^[0-9]{5}(-[0-9]{4})?$");
  return re.test(zipCode);
};

export const simpleEmailValidation = (email) => {
  const re = /.*@[a-z0-9.-]*\./;
  return re.test(email);
};
