import { useContext, createContext, useEffect, useState } from "react";
import { getUser } from "../services/authService.js";

const UserContext = createContext(null);

export const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  // State Variables
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const updateUserState = async () => {
      const localStorageUser =
        JSON.parse(localStorage.getItem("userInfo")) || null;

      const user = await getUser();
      if (user) {
        setUserInfo({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        });
      }
      if (!user && localStorageUser) {
        setUserInfo({
          email: localStorageUser.email,
          firstName: localStorageUser.firstName,
          lastName: localStorageUser.lastName,
        });
      }
    };
    updateUserState();
  }, []);

  return (
    <UserContext.Provider value={{ setUserInfo, userInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
