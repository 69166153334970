import React from "react";
import styles from "./About.module.css";
import kitchen from "../../assets/cleanKitchen.jpg";


const About = () => {
  return (
    <main className={styles.aboutWrapper}>
      <section className={styles.copyWrapper}>
        <h1>Learn more about who we are</h1>
        <p>
          We are dedicated to providing our clients with exceptional cleaning
          services while prioritizing a great customer experience. Our team of
          trained professionals is committed to delivering a spotless clean,
          leaving your home or business in pristine condition.
        </p>
        <h5>Integrity & Community</h5>
        <p>
          We strive to reflect these values in every aspect of our business. Our
          team is passionate about building long-lasting relationships with our
          clients, and we are committed to earning your trust through honest
          communication and reliable service
        </p>
      </section>
      <section className={styles.imageWrapper}>
        <img src={kitchen} alt="Kitchen" className={styles.image}/>
      </section>
    </main>
  );
};

export default About;
