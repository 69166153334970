import { NavLink } from "react-router-dom";
import { useState } from "react";
import FreshStartCleanersLogo from "../../assets/FreshStartCleanersLogoSVG.svg";
import styles from "./NavBar.module.css";

const NavBar = ({ user, handleLogout }) => {
  const [openNav, setOpenNav] = useState(false);

  const isActive = ({ isActive }) => (isActive ? styles.active : "");
  return (
    <nav className={styles.topNav}>
      <span className={styles.menuIcon} onClick={() => setOpenNav((s) => !s)}>
        <span></span>
        <span></span>
        <span></span>
      </span>

      <div className={styles.offerBanner}>
        Save up to 30% on recurring bookings!
      </div>
      <div className={styles.wrapper}>
        <NavLink
          to="/"
          className={styles.logoWrapper}
          onClick={() => setOpenNav(false)}
        >
          <img
            src={FreshStartCleanersLogo}
            alt="Logo"
            className={styles.logo}
          />
        </NavLink>
        <ul className={openNav ? styles.navActive : styles.navInactive}>
          <li>
            <NavLink
              to="/booking"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              Booking
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/faq"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              FAQ
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/account"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              My Account
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/corporate"
              className={isActive}
              onClick={() => setOpenNav(false)}
            >
              Corporate Cleanings
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
