import React from "react";
import { Routes, Route } from "react-router-dom";
import MyBookings from "../MyBookings/MyBookings";
import styles from "./AccountBuilder.module.css";
import SideBar from "../../components/Sidebar/SideBar";
import MyAccount from "../MyAccount/MyAccount";

const AccountBuilder = ({ user, logout }) => {
  return (
    <main className={styles.accountWrapper}>
      <SideBar logout={logout} />
      <section style={{ padding: "20px 20px 20px 90px", width: "100%" }}>
        <Routes>
          <Route path="/">
            <Route index element={<MyAccount user={user} />} />
            <Route
              path="manage-bookings"
              element={<MyBookings user={user} />}
            />
          </Route>
        </Routes>
      </section>
    </main>
  );
};

export default AccountBuilder;
