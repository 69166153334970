const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/check-distance`;

export const userDistance = async (zipCode) => {
  try {
    const res = await fetch(`${BASE_URL}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ zipCode }),
    });
    const data = await res.json();
    return data.validLocation;
  } catch (err) {
    return false;
  }
};
