import React from "react";
import styles from "./Contact.module.css";

const Contact = () => {
  return (
    <main className={styles.contactWrapper}>
      <div>
        <div className={styles.heading}>
          <h1>We'd love to hear from you!</h1>
        </div>
        <div className={styles.talkToUs}>
          <h1>TALK TO US:</h1>
          <p>Call&nbsp;us: {process.env.REACT_APP_COMPANY_PHONE_NUMBER}</p>
          <p>Email&nbsp;us: bookings@freshstartcleaners.us</p>
        </div>
      </div>
    </main>
  );
};

export default Contact;
