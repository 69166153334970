import React from "react";

export const defaultStyle = {
  width: "100%",
  backgroundColor: "#FFFFFF",
  boxShadow:
    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
};

const LargeCard = ({
  children,
  styles = defaultStyle,
  additionalStyles = {},
  clickHandler,
}) => {
  return (
    <div
      style={{ ...defaultStyle, ...additionalStyles }}
      onClick={clickHandler ? clickHandler : null}
    >
      {children}
    </div>
  );
};

export default LargeCard;
