import React, { useState, useEffect } from "react";
import styles from "./BookingConfirmed.module.css";
import LargeCard from "../../components/LargeCard/LargeCard";
import PillButton from "../../components/PillButton/PillButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useUserContext } from "../../providers/userContext";
import { signup, findUser } from "../../services/authService.js";
import { Link } from "react-router-dom";

const BookingConfirmed = ({ user }) => {
  // Context
  const { userInfo } = useUserContext();

  const [authData, setAuthData] = useState({
    email: userInfo?.email || "",
    password: "",
  });

  const [accountExists, setAccountExists] = useState("Loading");
  const [accountCreated, setAccountCreated] = useState(false);

  const handleCreateAccount = async () => {
    const data = await signup({
      email: authData.email,
      password: authData.password,
      ...userInfo,
    });

    if (data) {
      setAccountCreated(true);
    }
  };

  useEffect(() => {
    const localStorageVariable = JSON.parse(localStorage.getItem("userInfo"));
    const checkForUser = async () => {
      setAccountExists(
        await findUser(userInfo?.email || localStorageVariable.email)
      );
    };

    if (!authData.email) {
      setAuthData((s) => ({ ...s, email: localStorageVariable?.email || "" }));
    }

    checkForUser();
  }, []);

  return (
    <div className={styles.bookingConfirmedContainer}>
      {accountExists === "Loading" ? (
        <>
          <Stack spacing={3}>
            <Skeleton
              variant="rectangular"
              width={320}
              height={70}
              sx={{ minWidth: "300px", width: "100%" }}
              animation="wave"
            />
            <Skeleton
              width={320}
              height={20}
              variant="rounded"
              animation="wave"
            />
            <Skeleton
              width={320}
              height={70}
              variant="rounded"
              animation="wave"
            />
          </Stack>
        </>
      ) : (
        <>
          <LargeCard
            additionalStyles={{
              textAlign: "center",
              maxWidth: "900px",
              padding: "15px",
            }}
          >
            <h1 className={styles.headline}>Your booking is confirmed!</h1>
            <h2 className={styles.subHeadline}>
              Check your email for your invoice and booking details
            </h2>
            {user && accountExists && (
              <h2 className={styles.subHeadline} style={{ marginTop: "10px" }}>
                Click <Link to="/account">here</Link> to view all your bookings
              </h2>
            )}
            {!user && accountExists && (
              <h2 className={styles.subHeadline} style={{ marginTop: "10px" }}>
                Click <Link to="/account">here</Link> to login and manage your
                bookings
              </h2>
            )}
          </LargeCard>
          {!user && !accountExists && (
            <LargeCard
              additionalStyles={{
                textAlign: "center",
                maxWidth: "900px",
                padding: accountCreated ? "0px" : "15px",
                height: accountCreated ? "150px" : undefined,
                position: "relative",
                transition: "all .15s ease-in-out",
              }}
            >
              <div
                style={{
                  display: accountCreated ? "none" : "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  transition: "all .15s ease-in-out",
                }}
              >
                <h1 className={styles.headline}>One step account setup</h1>
                <h2 className={styles.subHeadline}>
                  Manage recurring bookings & view upcoming cleanings. Just
                  create a password!
                </h2>
                <input
                  type="text"
                  value={authData.email}
                  readOnly={true}
                  className={styles.email}
                />
                <input
                  type="password"
                  className={styles.password}
                  value={authData.password}
                  placeholder="Create a password"
                  onChange={(e) =>
                    setAuthData((s) => ({ ...s, password: e.target.value }))
                  }
                />
                <PillButton
                  fontSize="16px"
                  borderRadius="6px"
                  width="250px"
                  margin="10px 0 0 0"
                  clickHandler={handleCreateAccount}
                >
                  Create Account
                </PillButton>
              </div>
              {accountCreated && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all .15s ease-in-out",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "30px",
                      transition: "all .15s ease-in-out",
                    }}
                  >
                    <h2 className={styles.subHeadline}>
                      Account successfully created
                    </h2>
                    <svg
                      className={styles.checkmark}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className={styles.checkmark__circle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className={styles.checkmark__check}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </LargeCard>
          )}
        </>
      )}
    </div>
  );
};

export default BookingConfirmed;
