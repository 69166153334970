import styles from "./Booking.module.css";
import LargeCard from "../../components/LargeCard/LargeCard";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { GiBrickWall, GiWashingMachine, GiHomeGarage } from "react-icons/gi";
import { CgSmartHomeWashMachine } from "react-icons/cg";
import ExtraItem from "../../components/ExtraItem/ExtraItem";
import { TbWindow } from "react-icons/tb";
import { BiFridge, BiCabinet } from "react-icons/bi";
import { FaUtensils } from "react-icons/fa";
import { IoShirtOutline } from "react-icons/io5";
import { MdMicrowave, MdBlindsClosed } from "react-icons/md";
import { BsBookshelf } from "react-icons/bs";
import Calendar from "../../components/Calender/Calendar.jsx";
import BasicModal from "../../components/BasicModal/BasicModal";
import cleaningIcon from "../../assets/Step_2.png";
import homeIcon from "../../assets/homeIcon.svg";
import schedule from "../../assets/CalenderTransparent.png";
import repeat from "../../assets/repeatIcon.png";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "../../components/CardSection/CardSection";
import PillButton from "../../components/PillButton/PillButton";
import { useUserContext } from "../../providers/userContext";
import { getPrice } from "../../services/priceService";
import {
  validateForm,
  validateZipCode,
  simpleEmailValidation,
} from "../../helpers/validation.js";
import { userDistance } from "../../services/distanceService.js";
import analyticEvent from "../../helpers/googleAnalytics";

const Booking = ({ user }) => {
  // Context
  const { setUserInfo } = useUserContext();

  // Constants
  const stripe = useStripe();
  const elements = useElements();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const requiredFormField = {
    cleaningPackage: true,
    bedrooms: true,
    bathrooms: true,
    kitchens: true,
    cleanliness: true,
    addons: false,
    selectedTime: true,
    selectedDate: true,
    frequency: true,
    state: true,
    firstName: true,
    lastName: true,
    phoneNumber: true,
    email: true,
    address: true,
    city: true,
    aptSuite: false,
    zipCode: true,
    instructions: false,
  };

  const additionalChecks = [
    {
      fieldName: "email",
      validationCase: simpleEmailValidation,
    },
    {
      fieldName: "zipCode",
      validationCase: validateZipCode,
    },
    {
      fieldName: "selectedDate",
      validationCase: (val) => val !== "Click To Choose Date",
    },
  ];

  const urlParam = pathname.split("/")[2] ?? "Standard Clean";
  const defaultCleaningPackage =
    urlParam === "deep"
      ? "Deep Cleaning"
      : urlParam === "Standard Clean" || urlParam === "standard"
      ? "Standard Clean"
      : "Moving Clean";
  const cleaningPackages = ["Standard Clean", "Deep Cleaning", "Moving Clean"];
  const cleanliness = ["Slightly Dirty", "Pretty Dirty", "Very Dirty"];
  const howOften = ["One Time", "Weekly", "Bi-Weekly", "Monthly"];
  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const extraItems = [
    { name: "Inside Oven", icon: CgSmartHomeWashMachine },
    { name: "Walls", icon: GiBrickWall },
    { name: "Inside Windows", icon: TbWindow },
    { name: "Inside the Fridge", icon: BiFridge },
    { name: "Inside Cabinets", icon: BiCabinet },
    { name: "Organization", icon: BsBookshelf },
    { name: "Inside Dishwasher", icon: FaUtensils },
    { name: "Inside Garage", icon: GiHomeGarage },
    { name: "Laundry", icon: IoShirtOutline },
    { name: "Blinds", icon: MdBlindsClosed },
    { name: "Inside Washer/Dryer", icon: GiWashingMachine },
    { name: "Microwave", icon: MdMicrowave },
  ];

  const defaultFormValues = {
    cleaningPackage: defaultCleaningPackage,
    bedrooms: 1,
    bathrooms: 1,
    kitchens: 1,
    cleanliness: "Slightly Dirty",
    addons: {},
    selectedTime: "10:00am - 12:00pm",
    frequency: "One Time",
    state: "MD",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    aptSuite: "",
    zipCode: "",
    instructions: "",
    selectedDate: "Click To Choose Date",
  };

  const userFormValues = {
    cleaningPackage: defaultCleaningPackage,
    bedrooms: 1,
    bathrooms: 1,
    kitchens: 1,
    cleanliness: "Slightly Dirty",
    addons: {},
    selectedTime: "10:00am - 12:00pm",
    frequency: "One Time",
    state: user?.state || "MD",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phoneNumber || "",
    email: user?.email || "",
    address: user?.address || "",
    city: user?.city || "",
    aptSuite: user?.aptSuite || "",
    zipCode: user?.zipCode || "",
    instructions: "",
    selectedDate: "Click To Choose Date",
  };

  // State Variables
  const [formData, setFormData] = useState(
    user ? userFormValues : defaultFormValues
  );
  const [selectedDate, setSelectedDate] = useState("Click To Choose Date");
  const [showCalender, setShowCalender] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [serviceTotal, setServiceTotal] = useState(95);
  const [discount, setDiscount] = useState(0);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validDistance, setValidDistance] = useState("");

  const [servicePrices, setServicePrices] = useState({
    bedrooms: 95,
    bathrooms: 0,
    kitchens: 0,
  });

  const [availableTimes] = useState([
    "10:00am - 12:00pm",
    "1:00pm - 3:00pm",
    "4:00pm - 6:00pm",
    "6:00pm - 8:00pm",
  ]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "450px",
    minWidth: "300px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
  };

  // Helper Functions
  const handleConversion = (serviceTotal) => {
    window.gtag("event", "conversion", {
      send_to: "AW-11323026336/successful_booking",
      value: parseFloat(serviceTotal),
      currency: "USD",
    });
  };

  // Event Handlers
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const checkUserLocation = async () => {
      // enable loading  dots while user types
      if (e.target.name === "zipCode") {
        setValidDistance("typing");
      }
      // Prompt the user if they are within the service area once they have entered a valid zip code
      if (e.target.name === "zipCode" && validateZipCode(e.target.value)) {
        if (await userDistance(e.target.value)) setValidDistance(true);
        else setValidDistance(false);
      }
      // Replace the prompt if the user erases everything in the textbox
      if (e.target.name === "zipCode" && e.target.value === "") {
        setValidDistance("");
      }
    };

    checkUserLocation();
  };

  const handleSubmit = async (event) => {
    const validForm = validateForm(
      formData,
      requiredFormField,
      additionalChecks
    );

    if (!validDistance) {
      return;
    }

    if (!validForm) {
      setFormError(true);
      return;
    }

    event.preventDefault();
    setLoading(true);

    // Get the Client Secret
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/create-payment-intent`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ formData, user: user ? user : undefined }),
      }
    );

    const bookingData = await data.json();

    if (bookingData.error) {
      setPaymentError("Internal Server Error");
      console.log("Server Error");
      setLoading(false);
      return;
    }

    const { clientSecret } = bookingData;

    if (!stripe || !elements || !clientSecret) {
      console.error("Client Secret Error!");
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: formData.firstName + "" + formData.lastName,
        },
      },
    });

    setLoading(false);
    setUserInfo(formData);
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
      })
    );
    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      setPaymentError(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        console.log("payment success!");
        navigate("/booking-confirmed");

        if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
          // google analytics purchase event
          analyticEvent(
            "purchase",
            "completed purchase",
            parseFloat(serviceTotal - discount) || 0
          );
          // google ads conversion event
          handleConversion(serviceTotal - discount || 0);
        }
      }
    }
  };

  // Side Effects
  useEffect(() => {
    setFormData((formData) => ({ ...formData, selectedDate }));
  }, [selectedDate]);

  useEffect(() => {
    const fetchRoomCost = async () => {
      let bedroomCost = await getPrice("bedroom", formData.bedrooms);
      let bathroomCost = await getPrice("bathroom", formData.bathrooms);
      let kitchenCost = await getPrice("kitchen", formData.kitchens);

      const prices = {
        bedrooms: bedroomCost?.cost,
        bathrooms: bathroomCost?.cost,
        kitchens: kitchenCost?.cost,
      };

      setServicePrices(prices);

      setServiceTotal(() => {
        let addonTotal = Object.values(formData.addons).reduce((cv, acc) => {
          return (acc += cv);
        }, 0);

        let total =
          addonTotal +
          bedroomCost?.cost +
          bathroomCost?.cost +
          kitchenCost?.cost;

        if (formData.cleanliness === "Pretty Dirty") {
          total = Math.ceil(total * 1.08);
        }

        if (formData.cleanliness === "Very Dirty") {
          total = Math.ceil(total * 1.18);
        }

        if (formData.cleaningPackage === "Deep Cleaning") {
          total = Math.ceil(total * 1.2);
        }

        if (formData.cleaningPackage === "Moving Clean") {
          total = Math.ceil(total * 1.3);
        }

        return total;
      });
    };

    fetchRoomCost();

    switch (formData.frequency) {
      case "Weekly":
        setDiscount(Math.floor(serviceTotal * 0.27));
        break;
      case "Bi-Weekly":
        setDiscount(Math.floor(serviceTotal * 0.2));
        break;
      case "Monthly":
        setDiscount(Math.floor(serviceTotal * 0.1));
        break;
      default:
        setDiscount(0);
        break;
    }
  }, [formData]);

  return (
    <section className={styles.bookingWrapper}>
      {showCalender && (
        <BasicModal open={showCalender} setOpen={setShowCalender}>
          <Box sx={modalStyle}>
            <Calendar
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              setShowCalender={setShowCalender}
            />
          </Box>
        </BasicModal>
      )}
      <div className={styles.formSection}>
        <LargeCard
          additionalStyles={{
            padding: "10px 20px",
            borderRadius: "5px",
            minWidth: "300px",
            maxWidth: "800px",
          }}
        >
          <div className={styles.cleaningPackage}>
            <h1 className={styles.RegularFontWeight}>Availability</h1>
            <h2 className={styles.subheading}>
              {validDistance === "" &&
                "Enter your zip code to verify you're in our service area"}
              {validDistance === false && (
                <span style={{ color: "red" }}>
                  You're outside of our service area
                </span>
              )}
              {validDistance === true && (
                <span style={{ color: "green" }}>
                  You're inside of our service area!
                </span>
              )}
              {validDistance === "typing" && (
                <div className={styles["col-3"]}>
                  <div className={styles.snippet} data-title="dot-collision">
                    <div className={styles.stage}>
                      <div className={styles[`dot-collision`]}></div>
                    </div>
                  </div>
                </div>
              )}
            </h2>

            <TextField
              id="outlined-basic"
              label="Zip Code *"
              variant="outlined"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              sx={{ margin: "10px 0 0 0", width: "100%" }}
              error={false}
            />
          </div>
          <span className={styles.hr}></span>
          <div className={styles.cleaningPackage}>
            <InputLabel id="cleaningPackage">
              Select a Cleaning Package
            </InputLabel>
            <Select
              sx={{
                width: "100%",
                maxWidth: "500px",
                minWidth: "200px",
                height: "40px",
              }}
              labelId="cleaningPackage"
              id="cleaningPackage"
              value={formData.cleaningPackage}
              name="cleaningPackage"
              onChange={handleChange}
              input={<OutlinedInput label="Choose Your Cleaning Package" />}
            >
              {cleaningPackages.map((_package) => (
                <MenuItem key={_package} value={_package}>
                  {_package}
                </MenuItem>
              ))}
            </Select>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.homeDetails}>
            <h1 className={styles.RegularFontWeight}>
              Tell Us About Your Home
            </h1>
            <span>
              <InputLabel id="bedrooms">Bedrooms</InputLabel>
              <Select
                sx={{ width: "100%", height: "40px" }}
                labelId="bedrooms"
                id="bedrooms"
                value={formData.bedrooms}
                name="bedrooms"
                onChange={handleChange}
                input={<OutlinedInput label="Bedrooms" />}
              >
                {[1, 2, 3, 4, 5, 6, 7].map((rooms) => (
                  <MenuItem key={rooms} value={rooms}>
                    {rooms}
                  </MenuItem>
                ))}
              </Select>
            </span>
            <span>
              <InputLabel id="bathrooms">Bathrooms</InputLabel>
              <Select
                sx={{ width: "100%", height: "40px" }}
                labelId="bathrooms"
                id="bathrooms"
                value={formData.bathrooms}
                name="bathrooms"
                onChange={handleChange}
                input={<OutlinedInput label="Bathrooms" />}
              >
                {[1, 2, 3, 4, 5, 6, 7].map((rooms) => (
                  <MenuItem key={rooms} value={rooms}>
                    {rooms}
                  </MenuItem>
                ))}
              </Select>
            </span>
            <span>
              <InputLabel id="kitchens">Kitchens</InputLabel>
              <Select
                sx={{ width: "100%", height: "40px" }}
                labelId="kitchens"
                id="kitchens"
                value={formData.kitchens}
                name="kitchens"
                onChange={handleChange}
                input={<OutlinedInput label="Kitchens" />}
              >
                {[1, 2, 3, 4].map((rooms) => (
                  <MenuItem key={rooms} value={rooms}>
                    {rooms}
                  </MenuItem>
                ))}
              </Select>
            </span>
            <span>
              <InputLabel id="cleanliness">Home Cleanliness</InputLabel>
              <Select
                sx={{ width: "100%", height: "40px" }}
                labelId="cleanliness"
                id="cleanliness"
                value={formData.cleanliness}
                name="cleanliness"
                onChange={handleChange}
                input={<OutlinedInput label="Home Cleanliness" />}
              >
                {cleanliness.map((rooms) => (
                  <MenuItem key={rooms} value={rooms}>
                    {rooms}
                  </MenuItem>
                ))}
              </Select>
            </span>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.extras}>
            <h1 className={styles.RegularFontWeight}>Choose Your Extras</h1>
            <div className={styles.extraItemsList}>
              {extraItems.map(({ icon, name }) => (
                <div className={styles.extraItemWrapper} key={name}>
                  <ExtraItem
                    icon={icon}
                    state={formData}
                    setState={setFormData}
                    name={name}
                  />
                  <p>{name}</p>
                </div>
              ))}
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.bookingDateTime}>
            <h1 className={styles.RegularFontWeight}>Booking Date & Time</h1>
            <h2 className={styles.subheading}>
              <span>
                Choose the date and <strong>arrival window</strong> that works
                for you. If you need a last-minute appointment give us a call at
                {process.env.REACT_APP_COMPANY_PHONE_NUMBER}.
                <strong>Please note:</strong> Your arrival window is the block
                of time in which the cleaners may arrive.
              </span>
            </h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <div
                onClick={() => setShowCalender(true)}
                className={styles.selectDate}
                style={{
                  border:
                    formError &&
                    formData.selectedDate === "Click To Choose Date"
                      ? "1px solid red"
                      : "1px solid #bfbdbd",
                  color:
                    formError &&
                    formData.selectedDate === "Click To Choose Date"
                      ? "red"
                      : "#636262",
                }}
              >
                {!selectedDate ? "Click To Choose Date" : selectedDate}
              </div>
              <span style={{ flexGrow: 1, minWidth: "200px" }}>
                <InputLabel id="availableTimes">Arrival Time</InputLabel>
                <Select
                  sx={{ width: "100%", height: "40px" }}
                  labelId="availableTimes"
                  id="availableTimes"
                  value={formData.selectedTime}
                  name="selectedTime"
                  onChange={handleChange}
                  input={<OutlinedInput label="Arrival Time" />}
                >
                  {availableTimes.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </span>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.howOften}>
            <h1 className={styles.RegularFontWeight}>How Often?</h1>
            <h2 className={styles.subheading}>
              It's all about matching you with the perfect clean for your home.
              Scheduling is flexible. Cancel or reschedule anytime. Discounts
              are applied based on selection.
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                flexWrap: "wrap",
              }}
            >
              {howOften.map((option) => (
                <span
                  style={{
                    border:
                      formData?.frequency === option
                        ? "2px solid #FF9E00"
                        : "2px solid #00000030",
                    backgroundColor:
                      formData?.frequency === option
                        ? "#FF9E0022"
                        : "transparent",
                  }}
                  className={styles.frequency}
                  onClick={() => {
                    setFormData((state) => ({ ...state, frequency: option }));
                  }}
                  key={option}
                >
                  {option}
                </span>
              ))}
            </div>
          </div>
          <span className={styles.hr}></span>
          <div>
            <h1 className={styles.RegularFontWeight}>Who Are You</h1>
            <h2 className={styles.subheading}>
              This information will be used to contact you about your service.
            </h2>
            <div style={{ width: "100%", display: "flex", margin: "10px 0" }}>
              <TextField
                id="outlined-basic"
                label="First Name *"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  margin: "0 10px",
                  flexGrow: 1,
                }}
                error={formError && formData.firstName === ""}
              />
              <TextField
                id="outlined-basic"
                label="Last Name *"
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                sx={{ margin: "0 10px", flexGrow: 1 }}
                error={formError && formData.lastName === ""}
              />
            </div>
            <div style={{ width: "100%", display: "flex", margin: "10px 0" }}>
              <TextField
                id="outlined-basic"
                label="Email *"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                sx={{ margin: "0 10px", flexGrow: 1 }}
                error={formError && !simpleEmailValidation(formData.email)}
              />
              <TextField
                id="outlined-basic"
                label="Phone Number *"
                value={formData.phoneNumber}
                name="phoneNumber"
                onChange={handleChange}
                variant="outlined"
                sx={{ margin: "0 10px", flexGrow: 1 }}
                error={formError && formData.phoneNumber === ""}
              />
            </div>
          </div>
          <span className={styles.hr}></span>
          <div>
            <h1 className={styles.RegularFontWeight}>Address</h1>
            <h2 className={styles.subheading}>
              Where would you like us to clean?
            </h2>
            <div>
              <div style={{ width: "100%", display: "flex", margin: "10px 0" }}>
                <TextField
                  id="outlined-basic"
                  label="Address *"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ margin: "0 10px", flexGrow: 3 }}
                  error={formError && formData.address === ""}
                />
                <TextField
                  id="outlined-basic"
                  label="Apt/Suite #"
                  name="aptSuite"
                  value={formData.aptSuite}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ margin: "0 10px", flexGrow: 1 }}
                />
              </div>
              <div style={{ width: "100%", display: "flex", margin: "10px 0" }}>
                <TextField
                  id="outlined-basic"
                  label="City *"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  variant="outlined"
                  sx={{ margin: "0 10px", flexGrow: 4 }}
                  error={formError && formData.city === ""}
                />
                <Select
                  sx={{ flexGrow: 1 }}
                  id="state"
                  value={formData.state}
                  name="state"
                  onChange={handleChange}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  id="outlined-basic"
                  label="Zip Code *"
                  variant="outlined"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  sx={{ margin: "0 10px", flexGrow: 1 }}
                  error={formError && !validateZipCode(formData.zipCode)}
                />
              </div>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div>
            <h1 className={styles.RegularFontWeight}>
              Comments & Special Instructions
            </h1>
            <h2 className={styles.subheading}>
              Special Instructions: Is there anything we should know? Example:
              "The Gate Code is 1234"
            </h2>
            <TextField
              sx={{ width: "100%", marginTop: "10px" }}
              id="outlined-multiline-static"
              label="Special Instructions"
              value={formData.instructions}
              name="instructions"
              onChange={handleChange}
              multiline
              rows={4}
            />
          </div>
          <span className={styles.hr}></span>
          <div className={styles.payment}>
            <h1 className={styles.RegularFontWeight}>Payment Details</h1>
            <div>
              <CardSection />
            </div>
          </div>

          <div className={styles.BookingInfoInline}>
            <span className={styles.hr3}></span>
            <div className={styles.serviceList}>
              <div
                style={{
                  maxWidth: "40px",
                  minWidth: "20px",
                  width: "10vw",
                  opacity: 0.4,
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  margin: "0 15px",
                }}
              >
                <img src={homeIcon} alt="icon" style={{ width: "100%" }} />
              </div>
              <div className={styles.items}>
                <div className={styles.itemWrapper}>
                  <p>
                    {formData.bedrooms}
                    {formData.bedrooms === "1" ? " Bedroom" : " Bedrooms"}
                  </p>
                  <span className={styles.priceWrapper}>
                    {servicePrices?.bedrooms !== undefined
                      ? `$${servicePrices.bedrooms}.00`
                      : "Error"}
                  </span>
                </div>
                <div className={styles.itemWrapper}>
                  <p>
                    {formData.bathrooms}
                    {formData.bathrooms === "1" ? " Bathroom" : " Bathrooms"}
                  </p>
                  <span className={styles.priceWrapper}>
                    {servicePrices?.bathrooms !== undefined
                      ? `$${servicePrices.bathrooms}.00`
                      : "Error"}
                  </span>
                </div>
                <div className={styles.itemWrapper}>
                  <p>
                    {formData.kitchens}
                    {formData.kitchens === "1" ? " Kitchen" : " Kitchens"}
                  </p>
                  <span className={styles.priceWrapper}>
                    {servicePrices?.kitchens !== undefined
                      ? `$${servicePrices.kitchens}.00`
                      : "Error"}
                  </span>
                </div>
                {Object.entries(formData.addons).map(([addon, val]) => (
                  <div key={addon}>
                    {val && (
                      <div className={styles.itemWrapper}>
                        <p>{addon}</p>
                        <span className={styles.priceWrapper}>${val}.00</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <span className={styles.hr3}></span>
            <div className={styles.dateTime}>
              <div
                style={{
                  maxWidth: "40px",
                  minWidth: "20px",
                  width: "10vw",
                  opacity: 0.4,
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  margin: "0 15px",
                }}
              >
                <img src={schedule} alt="icon" style={{ width: "100%" }} />
              </div>
              <div style={{ flexGrow: 5, textAlign: "center" }}>
                <p>{formData.selectedDate}</p>
                <p>{formData.selectedTime}</p>
              </div>
            </div>
            <span className={styles.hr3}></span>
            <div className={styles.dateTime}>
              <div
                style={{
                  maxWidth: "40px",
                  minWidth: "20px",
                  width: "10vw",
                  opacity: 0.4,
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  margin: "0 15px",
                }}
              >
                <img src={repeat} alt="icon" style={{ width: "100%" }} />
              </div>
              <div
                style={{
                  flexGrow: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{formData.frequency}</p>
              </div>
            </div>
            <span className={styles.hr3}></span>
            {formData?.frequency !== "One Time" && (
              <>
                <div className={styles.subtotalWrapper}>
                  <span className={styles.label}>Subtotal</span>
                  <span className={styles.amount}>{`$${serviceTotal}.00`}</span>
                </div>

                <div className={styles.discount}>
                  <span className={styles.label}>Discount</span>
                  <span className={styles.amount}>{`$${discount}.00`}</span>
                </div>
                <span className={styles.hr3}></span>
              </>
            )}
            <div className={styles.total}>
              <span className={styles.label}>Total</span>
              <span className={styles.totalNumber}>
                {serviceTotal && `$${serviceTotal - discount}.00`}
              </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* ----------------------------------------- */}
            <PillButton
              clickHandler={handleSubmit}
              loading={loading}
              margin="20px 0"
              // padding="20px 40px"
              width="100%"
              height="50px"
              fontWeight={300}
              disabled={loading}
              borderRadius="6px"
              fontSize="18px"
            >
              CONFIRM BOOKING
            </PillButton>
            {paymentError && (
              <span style={{ color: "red" }}>
                <i>{paymentError}</i>
              </span>
            )}
            {formError && (
              <span style={{ color: "red" }}>
                <i>Invalid entry or entries</i>
              </span>
            )}
            {validDistance === false && (
              <span style={{ color: "red" }}>
                <i>You're outside of our service area</i>
              </span>
            )}
          </div>
        </LargeCard>
      </div>
      <div className={styles.previewSection}>
        <LargeCard
          additionalStyles={{
            padding: "10px 20px",
            borderRadius: "5px",
            position: "fixed",
            width: "35vw",
            maxWidth: "400px",
            minWidth: "250px",
            zIndex: 9,
            userSelect: "none",
          }}
        >
          <div className={styles.header}>
            <div className={styles.iconWrapper}>
              <img src={cleaningIcon} alt="icon" className={styles.icon} />
            </div>
            <div className={styles.copy}>
              <span>BOOKING DETAILS</span>
              <p>{formData.cleaningPackage}</p>
            </div>
          </div>
          <span className={styles.hr3}></span>
          <div className={styles.serviceList}>
            <div
              style={{
                maxWidth: "40px",
                minWidth: "20px",
                width: "10vw",
                opacity: 0.4,
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                margin: "0 15px",
              }}
            >
              <img src={homeIcon} alt="icon" style={{ width: "100%" }} />
            </div>
            <div className={styles.items}>
              <div className={styles.itemWrapper}>
                <p>
                  {formData.bedrooms}
                  {formData.bedrooms === "1" ? " Bedroom" : " Bedrooms"}
                </p>
                <span className={styles.priceWrapper}>
                  {servicePrices && `$${servicePrices.bedrooms}.00`}
                </span>
              </div>
              <div className={styles.itemWrapper}>
                <p>
                  {formData.bathrooms}
                  {formData.bathrooms === "1" ? " Bathroom" : " Bathrooms"}
                </p>
                <span className={styles.priceWrapper}>
                  {" "}
                  {servicePrices && `$${servicePrices.bathrooms}.00`}
                </span>
              </div>
              <div className={styles.itemWrapper}>
                <p>
                  {formData.kitchens}
                  {formData.kitchens === "1" ? " Kitchen" : " Kitchens"}
                </p>
                <span className={styles.priceWrapper}>
                  {servicePrices && `$${servicePrices.kitchens}.00`}
                </span>
              </div>
              {Object.entries(formData.addons).map(([addon, val]) => (
                <div key={addon}>
                  {val && (
                    <div className={styles.itemWrapper}>
                      <p>{addon}</p>
                      <span className={styles.priceWrapper}>${val}.00</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <span className={styles.hr3}></span>
          <div className={styles.dateTime}>
            <div
              style={{
                maxWidth: "40px",
                minWidth: "20px",
                width: "10vw",
                opacity: 0.4,
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                margin: "0 15px",
              }}
            >
              <img src={schedule} alt="icon" style={{ width: "100%" }} />
            </div>
            <div style={{ flexGrow: 5, textAlign: "center" }}>
              <p>{formData.selectedDate}</p>
              <p>{formData.selectedTime}</p>
            </div>
          </div>
          <span className={styles.hr3}></span>
          <div className={styles.dateTime}>
            <div
              style={{
                maxWidth: "40px",
                minWidth: "20px",
                width: "10vw",
                opacity: 0.4,
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                margin: "0 15px",
              }}
            >
              <img src={repeat} alt="icon" style={{ width: "100%" }} />
            </div>
            <div
              style={{
                flexGrow: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{formData.frequency}</p>
            </div>
          </div>
          <span className={styles.hr3}></span>
          {formData?.frequency !== "One Time" && (
            <>
              <div className={styles.subtotalWrapper}>
                <span className={styles.label}>Subtotal</span>
                <span className={styles.amount}>{`$${serviceTotal}.00`}</span>
              </div>

              <div className={styles.discount}>
                <span className={styles.label}>Discount</span>
                <span className={styles.amount}>{`$${discount}.00`}</span>
              </div>
              <span className={styles.hr3}></span>
            </>
          )}
          <div className={styles.total}>
            <span className={styles.label}>Total</span>
            <span className={styles.totalNumber}>
              {serviceTotal && `$${serviceTotal - discount}.00`}
            </span>
          </div>
        </LargeCard>
      </div>
    </section>
  );
};

export default Booking;
