import styles from "./Landing.module.css";
import PillButton from "../../components/PillButton/PillButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import step1 from "../../assets/Step_1.png";
import step2 from "../../assets/Step_2.png";
import step3 from "../../assets/Step_3.png";
import { useNavigate } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/StarBorder";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { useState } from "react";
import BasicModal from "../../components/BasicModal/BasicModal";

const Landing = ({ user }) => {
  const [openCallNow, setOpenCallNow] = useState(false);
  const navigate = useNavigate();

  const stepImages = [
    { img: step1, description: "Tell us about your home" },
    { img: step2, description: "Select your homes level of cleanliness" },
    {
      img: step3,
      description: "Schedule your preferred day and 2-HR booking window",
    },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: "20px 10px",
    transform: "translate(-50%, -50%)",
    maxWidth: "450px",
    minWidth: "300px",
    width: "70%",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
  };

  const tiers = [
    {
      title: "Standard Clean",
      subheader: "Starting At",
      price: "100",
      description: ["Ideal for apartments and other similar size spaces."],
      buttonText: "Book now",
      buttonVariant: "contained",
      urlParam: "standard",
    },
    {
      title: "Deep Clean",
      subheader: "Starting At",
      price: "150",
      description: [
        "Great for bigger homes or homes that need a  little extra attention",
      ],
      buttonText: "Book now",
      buttonVariant: "contained",
      urlParam: "deep",
    },
    {
      title: "Moving Clean",
      subheader: "Starting At",
      price: "280",
      description: ["Thorough cleaning to make your home look like new."],
      buttonText: "Book now",
      buttonVariant: "contained",
      urlParam: "moving",
    },
  ];

  return (
    <main className={styles.container}>
      {openCallNow && (
        <BasicModal open={openCallNow} setOpen={setOpenCallNow}>
          <Box sx={modalStyle}>
            Call us at {process.env.REACT_APP_COMPANY_PHONE_NUMBER}
          </Box>
        </BasicModal>
      )}
      <section className={styles.hero}>
        <div className={styles.heroCopy}>
          <h1 className={styles.bold}>Transforming Spaces.</h1>
          <h1 className={styles.regular}>One clean at a time.</h1>
          <div className={styles.CallToActionButtonWrapper}>
            <PillButton
              height="50px"
              width="175px"
              clickHandler={() => navigate("/booking")}
            >
              Book Now
            </PillButton>
            <PillButton
              height="50px"
              width="175px"
              clickHandler={() => setOpenCallNow(true)}
            >
              Call Now
            </PillButton>
          </div>
        </div>
        <div className={styles.heroArtwork}></div>
      </section>
      <section className={styles.plansWrapper}>
        <h1 className={styles.headline}>Browse Cleaning Plans</h1>
        <h2 className={styles.subHeadline}>
          Subscribe & Save! Enjoy lower prices when you sign up for recurring
          cleanings!
        </h2>

        <Container maxWidth="md" component="main" sx={{ margin: "20px 0" }}>
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier, i) => (
              <Grid
                item
                key={i}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /wk
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1" align="center">
                      {tier.description[0]}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      onClick={() => navigate(`/booking/${tier.urlParam}`)}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>
      <section className={styles.howItWorksWrapper}>
        <h1 className={styles.headline}>How It Works</h1>
        <h2 className={styles.subHeadline}>
          Visit our FAQ page to find answers to our most commonly asked
          questions.
        </h2>
        <div className={styles.stepsWrapper}>
          {stepImages.map((step, i) => (
            <div className={styles.stepWrapper} key={i}>
              <img src={step.img} alt="icon" className={styles.step} />
              <span>Step {i + 1}</span>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default Landing;
