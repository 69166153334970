import React from "react";
import styles from "./MyAccount.module.css";
import LargeCard from "../../components/LargeCard/LargeCard";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";

const MyAccount = ({ user }) => {
  const navigate = useNavigate();

  return (
    <section className={styles.myAccountWrapper}>
      <div className={styles.cardWrapper}>
        <h1>WELCOME {user?.firstName?.toUpperCase() || ""}</h1>
        <div className={styles.buttonWrapper}>
          <LargeCard
            additionalStyles={{
              maxWidth: "400px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "8px",
              userSelect: "none",
              padding: "0",
            }}
            clickHandler={() => navigate("/booking")}
          >
            <span className={styles.iconWrapperBook}>
              <DateRangeIcon className={styles.icon} />
            </span>
            <span className={styles.copyWrapperBook}>
              <h1 className={styles.headline}>Book Now</h1>
              <span>Schedule a booking for your next cleaning</span>
            </span>
          </LargeCard>

          <LargeCard
            additionalStyles={{
              maxWidth: "400px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "8px",
              userSelect: "none",
              padding: "0",
            }}
            clickHandler={() => navigate("manage-bookings")}
          >
            <span className={styles.iconWrapperManage}>
              <ManageAccountsIcon className={styles.icon} />
            </span>
            <span className={styles.copyWrapperManage}>
              <h1 className={styles.headline}>Manage Bookings</h1>
              <span>View your bookings and subscriptions</span>
            </span>
          </LargeCard>
        </div>
      </div>
    </section>
  );
};

export default MyAccount;
