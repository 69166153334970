import ReactGA from "react-ga4";

const analyticEvent = (category, action, value = undefined) => {
  ReactGA.event({
    category,
    action,
    value: value,
  });
};

export default analyticEvent;
