import React from "react";
import styles from "./PillButton.module.css";
import loadingIcon from "../../assets/loading.svg";

const PillButton = ({
  children,
  buttonStyles,
  width,
  fontSize,
  margin,
  height,
  padding,
  backgroundColor,
  color,
  loading = false,
  clickHandler = () => console.log("Clicked!"),
  disabled = false,
  fontWeight = 400,
  borderRadius,
}) => {
  return (
    <button
      className={styles.pillButton}
      style={
        buttonStyles
          ? { buttonStyles }
          : {
              backgroundColor: backgroundColor || "black",
              color: color || "white",
              borderRadius: borderRadius || "400px",
              border: "none",
              width: width || "fit-content",
              height: height || "40px",
              fontSize: fontSize || "20px",
              margin: margin || "0",
              padding: padding || "10px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              fontWeight,
            }
      }
      onClick={clickHandler}
      disabled={disabled}
    >
      {children}
      {loading && (
        <span className={styles.loading}>
          <img src={loadingIcon} alt="Loading" className={styles.loadingIcon} />
        </span>
      )}
    </button>
  );
};

export default PillButton;
