import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./CardSectionStyles.css";
import mastercard from "../../assets/mastercard.svg";
import discover from "../../assets/discover.svg";
import amex from "../../assets/amex.svg";
import visa from "../../assets/visa.svg";
import secure from "../../assets/secure-checkout.png";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <div>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="payment-badge-container">
        <img src={visa} alt="Visa" className="card-icon" />
        <img src={amex} alt="American Express" className="card-icon" />
        <img src={discover} alt="Discover" className="card-icon" />
        <img src={mastercard} alt="Master Card" className="card-icon" />
        <img src={secure} alt="Secure Checkout" className="card-icon" />
      </div>
    </div>
  );
}

export default CardSection;
