import React from "react";
import styles from "./ComingSoon.module.css";

const ComingSoon = () => {
  return <main className={styles.wrapper}>
    <section className={styles.comingSoon}>
        <h1>Coming Soon!</h1>
        <p>Please check back next time!</p>
    </section>
  </main>;
};

export default ComingSoon;
