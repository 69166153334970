import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import styles from "./FAQ.module.css";
import { Link } from "react-router-dom";

export default function SimpleAccordion() {
  return (
    <main className={styles.pageWrapper}>
      <div className={styles.header}>
        <p>frequently asked questions</p>
        <h1>Got a few questions?</h1>
      </div>
      <div className={styles.accordionWrapper}>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How Do I Book My Cleaning?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <p>
                Schedule all of your bookings using our booking form{" "}
                <Link to="/booking">Here</Link>
              </p>
              <p className={styles.list}>
                How to determine if your home is “Slightly Dirty, Pretty Dirty
                or Very Dirty.”
              </p>
              <p className={styles.list}>
                If your home hasn't been professionally cleaned within the last
                year, you should select “Very Dirty”.
              </p>
              <p className={styles.list}>
                If your home has been cleaned within the last 6 months, you
                should select “Pretty Dirty”
              </p>
              <p className={styles.list}>
                If your home has been professionally cleaned within the last 3
                months, you should select ”Slightly Dirty“
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>What Is Included In A Regular Cleaning?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              Do's:
              <ul>
                <li className={styles.list}>
                  Kitchen (clean sink out, counter tops, cabinet faces upper and
                  lower, stove top)
                </li>
                <li className={styles.list}>
                  All exterior surfaces in all rooms
                </li>
                <li className={styles.list}>Floors are mopped and vacuumed</li>
                <li className={styles.list}>
                  Bathroom (complete toilet clean, shower, handles, tub, drain)
                </li>
                <li className={styles.list}>
                  Bedroom (shelving, nightstands, bed frames, tidy up bed)
                </li>
                <li className={styles.list}>All window sills</li>
              </ul>
              Don'ts:
              <ul>
                <li className={styles.list}>
                  All Extras are not included (must be selected for an
                  additional cost)
                </li>
                <li className={styles.list}>
                  The extra time included in deep cleans allows for more
                  detailed work in the above mentioned Do's.
                </li>
              </ul>
              <i>Regular cleanings are capped at 3 hours</i>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>What Is Included In A Deep Cleaning?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              Everything included in a regular clean +
              <ul>
                <li className={styles.list}>Baseboards</li>
                <li className={styles.list}>Light switches & Door handles</li>
                <li className={styles.list}>Detailed Dusting</li>
                <li className={styles.list}>
                  Back Splashes and Faucet Fixtures are polished
                </li>
                <li className={styles.list}>
                  Light organization of all rooms (for detailed organization
                  select add on)
                </li>
                <li className={styles.list}>
                  Extra hour included for high detail areas
                </li>
              </ul>
              Don'ts:
              <ul>
                <li className={styles.list}>
                  All Extras are not included (must be selected for an
                  additional cost)
                </li>
              </ul>
              <i>Deep cleanings are capped at 4 hours</i>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Cancellations And Refunds</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>
                If you cancel before 48 hours you will receive a full refund to
                the payment information on file.
              </p>
              <p className={styles.list}>
                If you cancel within 48 hours of your booking, your booking is
                credited to your account to use whenever you'd like.
              </p>
              <p className={styles.list}>
                If you cancel within 24 hours, you will be charged a $70 fee and
                your remaining balance will be applied as store credit.
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Do You Work On Weekends?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>Yes!</p>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Is There A Rescheduling Fee?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>
                There is no Rescheduling fee as long as it's done before 24
                hours of your booking
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How Can I See/Manage My Bookings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>
                Sign into your account and all of your bookings are viewable
                under "Manage Bookings".
              </p>
              <p className={styles.list}>
                If you do not have an account, sign up for one using the same
                email you provided during your booking process. Your bookings
                will also be available under "Manage Bookings"
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
              Do I Have To Be Home When The Cleaners Arrive Or Depart?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>
                You do not! However if it's your first time signing up for a
                Fresh Start cleaning, initial introductions always help develop
                the personable relationship with your home specialist.
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Are Your Prices Negotiable?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>
                No, are rates are calculated by the information you provide on
                our booking page. We provide some of the lowest rates in the
                industry.
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<AddIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Am I Charged Upon Booking?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ padding: "0 10px" }}>
              <p className={styles.list}>
                Yes, this is to ensure your day and time is reserved. This may
                seem inconvenient and unorthodox as a service company. But due
                to fraudulent billings in the past we’ve changed to this
                standard to ensure all cleanings are paid for before service.
              </p>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </main>
  );
}
