import { useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Signup from "./pages/Signup/Signup";
import About from "./pages/About/About";
import FAQ from "./pages/FAQ/FAQ";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Contact from "./pages/Contact/Contact";
import Login from "./pages/LoginComponent/LoginComponent";
import Landing from "./pages/Landing/Landing";
import AccountBuilder from "./pages/builders/AccountBuilder";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import * as authService from "./services/authService";
import Footer from "./components/Footer/Footer";
import Booking from "./pages/Booking/Booking";
import BookingConfirmed from "./pages/BookingConfirmed/BookingConfirmed";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";

const App = () => {
  // State Variables
  const [user, setUser] = useState(authService.getUser());

  // Constants
  const stripePromise = loadStripe(
    process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"
      ? process.env.REACT_APP_PUBLISHABLE_KEY
      : process.env.REACT_APP_TEST_PUBLISHABLE_KEY
  );

  const navigate = useNavigate();

  // Google Analytics
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  ReactGA.send({
    hitType: "pageview",
    page: `${document.location.pathname}`,
    title: "Page View",
  });

  // HotJar
  const siteId = 3642474;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  // Helper Functions
  const handleLogout = () => {
    authService.logout();
    setUser(null);
    navigate("/");
  };

  const handleSignupOrLogin = () => {
    setUser(authService.getUser());
  };

  return (
    <>
      <NavBar user={user} handleLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Landing user={user} />} />
        <Route
          path="/signup"
          element={<Signup handleSignupOrLogin={handleSignupOrLogin} />}
        />
        <Route
          path="/login"
          element={<Login handleSignupOrLogin={handleSignupOrLogin} />}
        />
        <Route
          path="/changePassword"
          element={
            user ? (
              <ChangePassword handleSignupOrLogin={handleSignupOrLogin} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/booking/*"
          element={
            <Elements stripe={stripePromise}>
              <Booking user={user} />
            </Elements>
          }
        />
        <Route
          path="/booking-confirmed"
          element={<BookingConfirmed user={user} />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<ComingSoon />} />
        <Route path="/corporate" element={<ComingSoon />} />
        <Route
          path="/account/*"
          element={
            user ? (
              <AccountBuilder user={user} logout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
