import * as React from "react";
import BasicModal from "../../components/BasicModal/BasicModal";
import styles from "./MyBookings.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { getCustomerBookings } from "../../services/profileService.js";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function BasicTable({ user }) {
  const { useEffect, useState } = React;

  const [bookings, setBookings] = useState(null);
  const [selectedBookingData, setSelectedBookingData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: "20px 10px",
    transform: "translate(-50%, -50%)",
    maxWidth: "450px",
    minWidth: "300px",
    width: "70%",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
  };
  const oneTimeRowData =
    bookings && bookings?.filter((booking) => booking.isSubscription === false);

  const oneTimeRows =
    bookings &&
    oneTimeRowData.map((booking) => {
      return [
        createData(booking.date || "", booking?.service?.cleaningPackage || ""),
        booking,
      ];
    });

  const recurringRowData =
    bookings && bookings?.filter((booking) => booking.isSubscription === true);

  const recurringRows =
    bookings &&
    recurringRowData.map((booking) => {
      return [
        createData(booking.date || "", booking?.service?.cleaningPackage || ""),
        booking,
      ];
    });

  useEffect(() => {
    const fetchBookings = async () => {
      let bookings = await getCustomerBookings(user.email);
      let adjustedBookings = [];

      bookings.forEach((booking) => {
        const b = { ...booking, service: JSON.parse(booking.service) };
        adjustedBookings.push(b);
      });

      setBookings(adjustedBookings);
    };

    fetchBookings();
  }, [user.email]);

  return (
    <div className={styles.tableWrapper}>
      <BasicModal setOpen={setModalOpen} open={modalOpen}>
        <Box sx={modalStyle}>
          <Box sx={{ my: 1, mx: 2 }}>
            <Grid container alignItems="center" sx={{ width: "100%" }}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ marginRight: "30px" }}
                >
                  {`${
                    selectedBookingData?.service?.cleaningPackage || "Cleaning"
                  }`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6" component="div">
                  {`$${selectedBookingData?.price / 100 || ""}`}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              color="text.secondary"
              variant="body2"
              align="center"
              sx={{ margin: "5px 0" }}
            >
              {selectedBookingData?.date || ""}
            </Typography>
            <Typography
              color="text.secondary"
              variant="body2"
              align="center"
              sx={{ margin: "5px 0" }}
            >
              {selectedBookingData?.arrivalWindow || ""}
            </Typography>
            <Typography
              color="text.secondary"
              variant="body2"
              align="center"
              sx={{ margin: "5px 0" }}
            >
              {selectedBookingData?.serviceAddress || ""}
            </Typography>
          </Box>
          <Divider variant="middle" />
          <Box>
            <Typography gutterBottom variant="body1">
              Addons
            </Typography>
            <Stack spacing={1}>
              {selectedBookingData &&
                Object.keys(selectedBookingData?.service?.addons).map(
                  (addon, i) => <Chip key={i} label={addon} />
                )}
              {selectedBookingData &&
              Object.keys(selectedBookingData?.service?.addons).length ? (
                <></>
              ) : (
                <Chip label="No Addons" />
              )}
            </Stack>
          </Box>
          {/* <Box sx={{ mt: 2, ml: 1 }}>
            <Button disabled={true}>Request Update or Cancel</Button>
          </Box> */}
          <Typography
            gutterBottom
            variant="body5"
            sx={{ fontSize: "13px", mt: 2, textAlign: "center" }}
          >
            Online booking updates are currently unavailable
          </Typography>
          <Typography
            gutterBottom
            variant="body5"
            sx={{ fontSize: "13px", textAlign: "center" }}
          >
            Give us a call at {process.env.REACT_APP_COMPANY_PHONE_NUMBER}
          </Typography>
        </Box>
      </BasicModal>

      <div className={styles.oneTimeBookings}>
        <h1>One-Time Bookings</h1>
        <span>Select booking for details</span>
        {bookings ? (
          <>
            <TableContainer component={Paper} sx={{ maxWidth: "500px" }}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Booking Date</TableCell>
                    <TableCell align="right">Service Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oneTimeRows.map(([row, booking], i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => [
                        setSelectedBookingData(booking),
                        setModalOpen(true),
                      ]}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <Stack spacing={3}>
              <Skeleton
                variant="rectangular"
                width={320}
                height={70}
                sx={{ minWidth: "300px", width: "100%" }}
                animation="wave"
              />
              <Skeleton
                width={320}
                height={20}
                variant="rounded"
                animation="wave"
              />
              <Skeleton
                width={320}
                height={70}
                variant="rounded"
                animation="wave"
              />
            </Stack>
          </>
        )}
      </div>
      <div className={styles.oneTimeBookings}>
        <h1>Recurring Bookings</h1>
        <span>Select booking for details</span>
        {bookings ? (
          <>
            <TableContainer component={Paper} sx={{ maxWidth: "500px" }}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Booking Date</TableCell>
                    <TableCell align="right">Service Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recurringRows.map(([row, booking], i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => [
                        setSelectedBookingData(booking),
                        setModalOpen(true),
                      ]}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <Stack spacing={3}>
              <Skeleton
                variant="rectangular"
                width={320}
                height={70}
                sx={{ minWidth: "300px", width: "100%" }}
                animation="wave"
              />
              <Skeleton
                width={320}
                height={20}
                variant="rounded"
                animation="wave"
              />
              <Skeleton
                width={320}
                height={70}
                variant="rounded"
                animation="wave"
              />
            </Stack>
          </>
        )}
      </div>
    </div>
  );
}
