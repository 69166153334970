import * as React from "react";
import Modal from "@mui/material/Modal";

export default function BasicModal({ open, setOpen, children }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {children}
      </Modal>
    </div>
  );
}
