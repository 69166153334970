import React from "react";
import styles from "./ExtraItem.module.css";
import { getPrice } from "../../services/priceService";

const ExtraItem = ({ icon: Icon, state, name, setState }) => {
  return (
    <div
      style={{
        border: state?.addons[name]
          ? "3px solid #FF9E00"
          : "2px solid #00000030",
        backgroundColor: state?.addons[name] ? "#FF9E0022" : "transparent",
      }}
      className={styles.extraItem}
      onClick={async () => {
        let price = await getPrice(name);
        setState((state) =>
          !state?.addons[name]
            ? { ...state, addons: { ...state.addons, [name]: price?.cost } }
            : { ...state, addons: { ...state.addons, [name]: false } }
        );
      }}
    >
      <Icon className={styles.icon} />
    </div>
  );
};

export default ExtraItem;
