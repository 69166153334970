const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/price`;

const getPrice = async (service, count) => {
  try {
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ service, count }),
    });

    const json = await res.json();
    return json;
  } catch (error) {
    return error;
  }
};

export { getPrice };
