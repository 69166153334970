const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/users`;

const getCustomerBookings = async (email) => {
  try {
    const res = await fetch(`${BASE_URL}/bookings`, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify({ email }),
    });

    const json = await res.json();
    return json;
  } catch (error) {
    return error;
  }
};

export { getCustomerBookings };
